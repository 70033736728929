import {useLogger, useUniversalFetch} from '#imports';

async function getContent(humanId: string, locale: string, type: string): Promise<string | undefined> {
    const logger = useLogger();
    const endpoint = '/api/content';

    let result;
    try {
        result = await useUniversalFetch<string>(endpoint, {
            query: {
                humanId,
                locale,
                type,
            },
        });
    } catch (e: any) {
        logger.error(`Error while getting content with parameters ${humanId}, ${locale}, ${type}: ${e.message}`);
    }
    return result;
}

async function getLastVersionForContent(humanId: string, locale: string, type: string): Promise<string | undefined> {
    const logger = useLogger();
    const endpoint = '/api/content/versions/last';

    let result;
    try {
        result = await useUniversalFetch<string>(endpoint, {
            query: {
                humanId,
                locale,
                type,
            },
        });
    } catch (e: any) {
        logger.error(`Error while getting content with parameters ${humanId}, ${locale}, ${type}: ${e.message}`);
    }
    return result;
}

export function useContent() {
    return {
        getContent,
        getLastVersionForContent,
    };
}
