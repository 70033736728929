import {useLogger, useUniversalFetch, useUniversalPost} from '#imports';

async function getWebPref<T = string>(webPrefId: string, {identityId}: {identityId?: string} = {}): Promise<T | undefined> {
    const logger = useLogger();

    let endpoint = `/api/webpref/${webPrefId}`;
    if (identityId) {
        endpoint += `?identityId=${identityId}`;
    }

    let result;
    try {
        result = await useUniversalFetch<T>(endpoint);
    } catch (e: any) {
        logger.error(`Error while checking webpref "${webPrefId}": ${e.message}`);
    }
    return result;
}

async function setWebPref(webPrefId: string, value: string | boolean | object): Promise<void> {
    const logger = useLogger();
    const endpoint = `/api/webpref/${webPrefId}`;

    try {
        if (typeof value === 'boolean' || typeof value === 'string') {
            await useUniversalPost(endpoint, {query: {value}});
        }
        if (typeof value === 'object') {
            await useUniversalPost(endpoint, {body: value});
        }
    } catch (e: any) {
        logger.error(`Error while setting webpref "${webPrefId}": ${e.message}`);
    }
}

export function useWebPreferences() {
    return {
        getWebPref,
        setWebPref,
    };
}
